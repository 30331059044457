import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import LandingPage from "./components/landingpage";
// import RequestDemo from "./components/landingpage/request-demo-modal";
// import AboutUs from "./components/landingpage/about-us";
// import PrivacyPolicy from "./components/landingpage/privacy-policies";
// import TermsConditions from "./components/landingpage/terms-conditions";
// import ArticleDetails from "./components/landingpage/article-details";
// import Login from "./components/landingpage/login";
// import FeatureDetails from "./components/landingpage/feature-details";
// import Pricing from "./components/landingpage/pricing";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "./components/error-handler";
// import OurSolutions from "./components/landingpage/our-solution";
import { lazy, Suspense } from "react";
// import DemoCalendar from "./components/landingpage/calendar-demo";
import "./App.css";

const LandingPage = lazy(() => import("./components/landingpage"));
const RequestDemo = lazy(() =>
  import("./components/landingpage/request-demo-modal")
);
const AboutUs = lazy(() => import("./components/landingpage/about-us"));
const PrivacyPolicy = lazy(() =>
  import("./components/landingpage/privacy-policies")
);
const TermsConditions = lazy(() =>
  import("./components/landingpage/terms-conditions")
);
const FeatureDetails = lazy(() =>
  import("./components/landingpage/feature-details")
);
const Pricing = lazy(() => import("./components/landingpage/pricing"));
const OurSolutions = lazy(() =>
  import("./components/landingpage/our-solution")
);
const ArticleDetails = lazy(() =>
  import("./components/landingpage/article-details")
);

const LegalPersonalFormData = lazy(() =>
  import("./components/not-sell-personal-data")
);

const CookiePolicy = lazy(() =>
  import("./components/landingpage/cookie-policy")
);

const App = () => {
  return (
    <>
      <ErrorBoundary
        FallbackComponent={() => <ErrorHandler />}
        onError={(error) => console.log("Error happened", error)}
      >
        <div className="App">
          <Suspense
            fallback={
              <div className="page-loading-wrp">
                <div className="page-loader"></div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/request-demo" element={<RequestDemo />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/our-solutions" element={<OurSolutions />} />
              <Route
                path="/features-details/:id"
                element={<FeatureDetails />}
              />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/article-details/:id" element={<ArticleDetails />} />
              <Route path="/legal-data" element={<LegalPersonalFormData />} />
              {/* <Route path="/request-login" element={Login} /> */}
              {/* <Route path="/request-calendar" element={DemoCalendar} /> */}
              {/* Redirect "sitemap.xml" path to the actual sitemap */}
              <Route
                path="/sitemap.xml"
                element={<Navigate to="/sitemap.xml" replace />}
              />
              {/* // page-not-found route */}
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </div>
      </ErrorBoundary>
    </>
  );
};

export default App;
